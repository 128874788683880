



























































































































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { VueEditor } from "vue2-editor";
import { FAQ as IFAQ } from "@/models/faq.interface";
import { EditorImages } from "@/mixins/editor-images";
import { StyleCheck } from "@/mixins/style-check";
import BarSearch from "@/components/Input/BarSearch.vue";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: { PageTitle, Loader, Empty, VueEditor, BarSearch, DotsMenu },
})
export default class FAQ extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  EditorImages,
  StyleCheck
) {
  $refs!: {
    faqForm: HTMLFormElement;
  };
  loader = false;
  loading = false;
  loadingDT = false;
  dialog = false;
  dialogDelete = false;
  previewDialog = false;
  showErrorAnswer = false;
  panel = 0;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];
  defaultFaq: IFAQ = {
    id: "",
    question: "",
    answer: "",
  };
  faq: IFAQ = { ...this.defaultFaq };
  search = "";
  options = {};
  clear = false;

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async searchFAQByText() {
    this.loadingDT = true;

    await this.getFAQ(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getFAQ(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("faq.answer", { deep: true })
  private async resetError(val) {
    if (val !== "" && val !== undefined && val !== null) {
      this.showErrorAnswer = false;
    }
  }

  private async getFAQ(page: number, size: number, text = ""): Promise<void> {
    await this.$store.dispatch("faq/getFAQ", { page, size, text }).catch(() => {
      const Error: Notification = {
        message: this.$tc("FAQ.fetchError.faq"),
        timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
        top: true,
        bottom: false,
        left: false,
        right: false,
        currentPath: this.$route.fullPath,
        error: true,
      };

      this.$store.dispatch("notifications/showNotification", Error);
    });
  }

  private async created() {
    this.loader = true;
    await this.getFAQ(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("FAQ.headers.question"),
        value: "question",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("FAQ.headers.answer"),
        value: "answer",
        align: "center",
        sortable: false,
      },

      ...(this.$perms.check({
        actions: "D",
        entities: [this.$constants.PRIVILEGES.FAQ],
      })
        ? [
            {
              text: this.$tc("FAQ.headers.actions"),
              value: "actions",
              sortable: false,
              align: "center",
            },
          ]
        : []),
    ];
  }

  private get faqs(): IFAQ[] {
    let response = this.$store.getters["faq/getFAQ"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.faqs) {
      return response.faqs;
    } else {
      return [];
    }
  }

  private async createFAQ() {
    this.loading = true;
    if (this.$refs.faqForm.validate() && this.faq.answer !== "") {
      await this.$store
        .dispatch("faq/createFAQ", {
          faq: this.faq,
        })
        .then(async () => {
          const Success: Notification = {
            message: this.$tc("FAQ.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          await this.getFAQ(
            this.pagination.page,
            this.pagination.itemsPerPage == -1
              ? this.pagination.totalItems
              : this.pagination.itemsPerPage,
            this.search
          );
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("FAQ.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
      if (
        this.faq.answer !== "" &&
        this.faq.answer !== undefined &&
        this.faq.answer !== null
      ) {
        this.showErrorAnswer = false;
      } else {
        this.showErrorAnswer = true;
      }
    }
  }

  private async deleteFAQConfirm(faq: IFAQ) {
    this.faq = { ...faq };
    this.dialogDelete = true;
  }

  private async deleteFAQ() {
    this.loading = true;
    await this.$store
      .dispatch("faq/deleteFAQ", this.faq.id)
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("FAQ.deleteSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getFAQ(
          this.pagination.page,
          this.pagination.itemsPerPage == -1
            ? this.pagination.totalItems
            : this.pagination.itemsPerPage,
          this.search
        );
        this.closeDelete();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("FAQ.deleteError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.faq = Object.assign(this.faq, this.defaultFaq);
  }

  resetValidations() {
    this.resetFormValidations([this.$refs.faqForm]);
  }

  private close() {
    this.dialog = false;
    this.clear = true;
    this.faq = Object.assign(this.faq, this.defaultFaq);
    this.showErrorAnswer = false;
    this.resetValidations();
  }

  private async openPreviewDialog(faq: IFAQ) {
    this.panel = 0;
    this.faq = { ...faq };
    this.previewDialog = true;
  }

  private async closePreviewDialog() {
    try {
      this.previewDialog = false;
      this.faq = Object.assign(this.faq, this.defaultFaq);
      this.showErrorAnswer = false;
      this.resetValidations();
    } catch (error) {
      this.previewDialog = false;
      this.faq = Object.assign(this.faq, this.defaultFaq);
      this.showErrorAnswer = false;
    }
  }
}
